import { useStaticQuery, graphql } from "gatsby"

export const useMetaCollectionGroups = () => {
  return useStaticQuery(graphql`
    query MetaCollectionGroups {
      groups: markdownRemark(
        fields: {
          contentKind: { eq: "meta" }
          slug: { eq: "collection/groups" }
        }
      ) {
        html
        plain: excerpt(format: PLAIN, truncate: false)
      }
    }
  `).groups
}
